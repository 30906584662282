<template>
  <router-view></router-view>
</template>

<script>


export default {
  name: "MeetMyAvatar",
  components: {
    
  }
};
</script>

<style scoped>
</style>
